/* Navbar.css */
.navbar {
  padding: 20px 0; /* Ajusta el padding para centrar mejor el contenido */
}

.navbar-brand {
  margin-left: 30px; /* Ajusta el margen izquierdo para alejar el logo de la orilla */
}

.navbar-nav {
  display: flex;
  justify-content: flex-end; /* Asegura que los items de navegación estén alineados a la derecha */
}

.nav-link {
  margin-left: 20px; /* Ajusta el margen entre los enlaces de navegación */
}
